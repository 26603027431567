import React, { useEffect } from "react";

import "../css/Reset.css";
import "../css/App.css";

function WhyWeBlock() {
  const tiles = [
    {
      title: "Прозрачные условия сотрудничества.",
      description:
        "Подробный договор с четкими гарантиями и обязательствами для обеих сторон.",
    },
    {
      title: "Гибкая система оплаты проекта.",
      description:
        "Возможность выбора удобной схемы оплаты: 70/30, 60/40, 50/50 или 20/80 за каждый этап работы.",
    },
    {
      title: "Постоянный диалог с клиентом.",
      description:
        "Обеспечиваем непрерывную связь через предпочтительные для вас каналы общения.",
    },
    {
      title: "Детальная отчетность на каждом этапе.",
      description:
        "Предоставляем полную отчетность по выполненным работам на каждом этапе проекта.",
    },
    {
      title: "Обучение управлению сайтом и техническая поддержка.",
      description:
        "Предлагаем консультации и рекомендации по работе с сайтом после его запуска, а также квалифицированную техническую поддержку.",
    },
    {
      title: "Демонстрация административной панели сайта.",
      description:
        "Предоставляем тестовый доступ к административной панели для ознакомления с функционалом управления сайтом.",
    },
    {
      title: "Глубокое погружение в специфику вашего бизнеса.",
      description:
        "Анализируем бизнес-процессы для разработки эффективного и конверсионного дизайна, максимально соответствующего целям вашего проекта.",
    },
    {
      title: "Персонализированный дизайн и разработка.",
      description:
        "Создаем уникальный дизайн и пишем чистый, оптимизированный код, полностью адаптированный под целевую аудиторию вашего сайта.",
    },
    {
      title: "SEO-оптимизация на этапе разработки.",
      description:
        "Учитываем все требования поисковых систем при разработке, чтобы обеспечить сайту высокие позиции в поисковой выдаче с момента запуска.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const tiles = document.querySelectorAll(".whyWe .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <>
      <section className="whyWe">
        <div className="floatTopTrinagle float"></div>
        <div className="floatBottomTrinagle float"></div>
        <div className="container">
          <h2>Почему мы?</h2>

          <div className="tiles-container">
            {tiles.map((tile, index) => {
              return (
                <div className="tile" key={index}>
                  <div className="row">
                    <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                    <h3>{tile.title}</h3>
                  </div>
                  <p>{tile.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyWeBlock;
