import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import { pagination } from "../../modules";
import FormBlock from "../../components/formBlock.jsx";
import WhyWe from "../../components/WhyWeBlock.jsx";

function ProjectsContainer() {
  function openCurrentProjectsType(event) {
    const CurrentButton = event.currentTarget;
    const NeedContainer = document.querySelector(CurrentButton.value);
    let AllContainers = document.querySelectorAll(".projects .container");
    let AllButtons = document.querySelectorAll(".projects .row-buttons button");

    const ColorChange = document.querySelector(".color-change");
    const ButtonsContainer = document.querySelector(".row-buttons");
    const ContainerWidth = ButtonsContainer.offsetWidth;
    const ContainerHeight = ButtonsContainer.offsetHeight;
    const CurrentButtonOffSet = CurrentButton.offsetLeft;
    const CurrentButtonOffSet_Mobile = CurrentButton.offsetTop;

    const CurrentPosition = (CurrentButtonOffSet / ContainerWidth) * 100;
    const CurrentPosition_Mobile =
      (CurrentButtonOffSet_Mobile / ContainerHeight) * 100;

    if (window.innerWidth > 768) {
      ColorChange.style.left = CurrentPosition + "%";
      if (CurrentPosition === 0) {
        ColorChange.classList.add("left");
        ColorChange.classList.remove("right");
      } else if (CurrentPosition > 0 && CurrentPosition < 74) {
        ColorChange.classList.remove("left");
        ColorChange.classList.remove("right");
      } else if (CurrentPosition >= 74) {
        ColorChange.classList.remove("left");
        ColorChange.classList.add("right");
      }
    } else if (window.innerWidth <= 768) {
      ColorChange.style.top = CurrentPosition_Mobile + "%";
      if (CurrentPosition_Mobile === 0) {
        ColorChange.classList.add("top");
        ColorChange.classList.remove("bottom");
      } else if (CurrentPosition_Mobile > 0 && CurrentPosition_Mobile < 75) {
        ColorChange.classList.remove("top");
        ColorChange.classList.remove("bottom");
      } else if (CurrentPosition_Mobile >= 75) {
        ColorChange.classList.remove("top");
        ColorChange.classList.add("bottom");
      }
    }

    console.log(CurrentPosition_Mobile);

    AllContainers.forEach((container) => {
      container.classList.remove("activeProjects");
    });
    NeedContainer.classList.add("activeProjects");

    AllButtons.forEach((button) => {
      button.classList.remove("activeProjectButton");
    });
    CurrentButton.classList.add("activeProjectButton");
  }

  const LandingTiles = [
    {
      type: "Landing",
      name: "HooBank",
      image: "/img/projectsImages/hoobank.png",
      link: `${process.env.PUBLIC_URL}/projects/hoobank/index.html`,
    },

    {
      type: "Landing",
      name: "Cyverpunk Promo",
      image: "/img/projectsImages/cyberpunk.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Cyberpunk.html`,
    },

    {
      type: "Landing",
      name: "Webovio",
      image: "/img/projectsImages/webovio.png",
      link: `${process.env.PUBLIC_URL}/projects/Webovio.html`,
    },

    {
      type: "Landing",
      name: "Konstruct",
      image: "/img/projectsImages/konstruct.png",
      link: `${process.env.PUBLIC_URL}/projects/Konstruct.html`,
    },

    {
      type: "Landing",
      name: "McDonald's NFT",
      image: "/img/projectsImages/mcdonald.png",
      link: `${process.env.PUBLIC_URL}/projects/MCdonaldNFT/index.html`,
    },

    {
      type: "Landing",
      name: "Survive at all",
      image: "/img/projectsImages/surviveAt.png",
      link: `${process.env.PUBLIC_URL}/projects/SurviveAt.html`,
    },

    {
      type: "Landing",
      name: "MoskowReg",
      image: "/img/projectsImages/moskowreg.png",
      link: `${process.env.PUBLIC_URL}/projects/moskowreg/index.html`,
    },

    {
      type: "Landing",
      name: "SQWD",
      image: "/img/projectsImages/freebie.png",
      link: `${process.env.PUBLIC_URL}/projects/SQWD.html`,
    },

    {
      type: "Landing",
      name: "FromBoard Delivery",
      image: "/img/projectsImages/fromboard.png",
      link: `${process.env.PUBLIC_URL}/projects/fromBoard.html`,
    },

    {
      type: "Landing",
      name: "F&B",
      image: "/img/projectsImages/FandB.png",
      link: `${process.env.PUBLIC_URL}/projects/F&B.html`,
    },

    {
      type: "Landing",
      name: "Castaway",
      image: "/img/projectsImages/castaway.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Castaway.html`,
    },

    {
      type: "Landing",
      name: "GlobalTechnick",
      image: "/img/projectsImages/global.png",
      link: `${process.env.PUBLIC_URL}/projects/Castaway.html`,
    },
  ];

  const CorporatTiles = [
    {
      type: "Корпоративный сайт",
      name: "Recediviz",
      image: "/img/projectsImages/Recidiviz.png",
      link: `${process.env.PUBLIC_URL}/projects/Recidiviz.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "KormVetAgro",
      image: "/img/projectsImages/kormvetagro.png",
      link: "https://kormvetagro.by",
    },
    {
      type: "Корпоративный сайт",
      name: "Eatly",
      image: "/img/projectsImages/eatly.png",
      link: `${process.env.PUBLIC_URL}/projects/Eatly.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "Exproforum",
      image: "/img/projectsImages/expoforum.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Expoforum.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "ink. house",
      image: "/img/projectsImages/houseproject.png",
      link: `${process.env.PUBLIC_URL}/projects/InkHouse.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "VISMA",
      image: "/img/projectsImages/visma.png",
      link: `${process.env.PUBLIC_URL}/projects/Visma.html`,
    },
  ];

  const BuisnessTiles = [
    {
      type: "Бизнес-сайт",
      name: "VR Nes Immercy",
      image: "/img/projectsImages/VR.png",
      link: `${process.env.PUBLIC_URL}/projects/VR.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Simple",
      image: "/img/projectsImages/simple.png",
      link: `${process.env.PUBLIC_URL}/projects/Simple.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Go Games",
      image: "/img/projectsImages/gogames.png",
      link: `${process.env.PUBLIC_URL}/projects/Gogames.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Revo",
      image: "/img/projectsImages/Revo.png",
      link: `${process.env.PUBLIC_URL}/projects/Revo.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Fashion",
      image: "/img/projectsImages/Fashion.png",
      link: `${process.env.PUBLIC_URL}/projects/Fashion.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Jeweler",
      image: "/img/projectsImages/jeweler.png",
      link: `${process.env.PUBLIC_URL}/projects/Juweler/index.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Pravo",
      image: "/img/projectsImages/pravo.png",
      link: `${process.env.PUBLIC_URL}/projects/pravo.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "RoboSchool",
      image: "/img/projectsImages/RoboSchool.png",
      link: `${process.env.PUBLIC_URL}/projects/roboSchool.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "Aura",
      image: "/img/projectsImages/aura.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Aura.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "CodlinAgency",
      image: "/img/projectsImages/codlinAgency.png",
      link: `${process.env.PUBLIC_URL}/projects/CodlinAgency/index.html`,
    },
  ];

  const OnlileStore = [
    {
      type: "Онлайн-магазин",
      name: "AromaStroyGroup",
      image: "/img/projectsImages/aromastroy.jpg",
      link: "https://aroma-stroy.by",
    },
    {
      type: "Онлайн-магазин",
      name: "HouseStroy",
      image: "/img/projectsImages/housestroy.jpg",
      link: "https://house-stroy.by",
    },
  ];

  useEffect(() => {
    const colorChange = document.querySelector(".color-change");
    if (window.innerWidth > 768) {
      colorChange.classList.add("left");
    } else {
      colorChange.classList.add("top");
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null, // относительно вьюпорта
        rootMargin: "0px",
        threshold: 0.3, // начать анимацию, когда элемент на 10% виден
      }
    );

    const tilesLanding = document.querySelectorAll(".landing .tile");
    const tilesCorporat = document.querySelectorAll(".corporat .tile");
    const tilesBuisness = document.querySelectorAll(".buisness .tile");
    const tilesStore = document.querySelectorAll(".onlinestore .tile");
    tilesLanding.forEach((tile) => observer.observe(tile));
    tilesCorporat.forEach((tile) => observer.observe(tile));
    tilesBuisness.forEach((tile) => observer.observe(tile));
    tilesStore.forEach((tile) => observer.observe(tile));

    return () => {
      tilesLanding.forEach((tile) => observer.unobserve(tile));
      tilesCorporat.forEach((tile) => observer.unobserve(tile));
      tilesBuisness.forEach((tile) => observer.unobserve(tile));
      tilesStore.forEach((tile) => observer.unobserve(tile));
    };
  }, []);
  return (
    <section className="projects scrollToThis">
      <h1>Портфолио</h1>
      <p>
        На этой странице вы найдете подборку наших проектов, доступных для
        публичного просмотра. Мы строго следуем политике конфиденциальности и
        демонстрируем только те работы, которые можем разглашать. Это лишь часть
        нашего портфолио, отражающая наш опыт и профессионализм в различных
        областях веб-разработки.
      </p>
      <div className="row-buttons">
        <div className="color-change">Landing's</div>
        <button
          className="activeProjectButton"
          value=".landing"
          onClick={(event) => openCurrentProjectsType(event)}
        >
          Landing's
        </button>
        <button
          value=".corporat"
          onClick={(event) => openCurrentProjectsType(event)}
        >
          Корпоративные сайты
        </button>
        <button
          value=".buisness"
          onClick={(event) => openCurrentProjectsType(event)}
        >
          Бизнес сайты
        </button>
        <button
          value=".onlinestore"
          onClick={(event) => openCurrentProjectsType(event)}
        >
          Онлайн-магазины
        </button>
      </div>

      <div className="container landing activeProjects">
        <div className="tiles-container">
          {LandingTiles.map((Landing, index) => {
            return (
              <div
                key={index}
                className="tile"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + Landing.image
                  })`,
                }}
              >
                <div className="bg">
                  <div className="block">
                    <p>{Landing.type}</p>
                    <h3>{Landing.name}</h3>
                  </div>
                  <a rel="noreferrer" target="_blank" href={Landing.link}>
                    Смотреть кейс
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination"></div>
      </div>

      <div className="container corporat">
        <div className="tiles-container">
          {CorporatTiles.map((Corporat, index) => {
            return (
              <div
                key={index}
                className="tile"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + Corporat.image
                  })`,
                }}
              >
                <div className="bg">
                  <div className="block">
                    <p>{Corporat.type}</p>
                    <h3>{Corporat.name}</h3>
                  </div>
                  <a rel="noreferrer" target="_blank" href={Corporat.link}>
                    Смотреть кейс
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination"></div>
      </div>

      <div className="container buisness">
        <div className="tiles-container">
          {BuisnessTiles.map((Buisness, index) => {
            return (
              <div
                key={index}
                className="tile"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + Buisness.image
                  })`,
                }}
              >
                <div className="bg">
                  <div className="block">
                    <p>{Buisness.type}</p>
                    <h3>{Buisness.name}</h3>
                  </div>
                  <a rel="noreferrer" target="_blank" href={Buisness.link}>
                    Смотреть кейс
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination"></div>
      </div>

      <div className="container onlinestore">
        <div className="tiles-container">
          {OnlileStore.map((Store, index) => {
            return (
              <div
                key={index}
                className="tile"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + Store.image
                  })`,
                }}
              >
                <div className="bg">
                  <div className="block">
                    <p>{Store.type}</p>
                    <h3>{Store.name}</h3>
                  </div>
                  <a rel="noreferrer" target="_blank" href={Store.link}>
                    Смотреть кейс
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination"></div>
      </div>
    </section>
  );
}
function Portfolio() {
  useEffect(() => {
    document.querySelector("header").classList.add("white");

    const itemsPerPage = window.innerWidth <= 768 ? 3 : 6;

    const containers = [".landing", ".corporat", ".buisness", ".onlinestore"];
    for (let i = 0; i < containers.length; i++) {
      pagination(itemsPerPage, containers[i]);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Портфолио | CodeSolutions - Решения для цифрового успеха</title>
        <meta
          name="description"
          content="Портфолио наших проектов в области веб-разработки, включая лендинги, корпоративные сайты, бизнес-сайты и онлайн-магазины."
        />
        <meta
          name="keywords"
          content="портфолио, веб-разработка, лендинги, корпоративные сайты, бизнес-сайты, онлайн-магазины"
        />
        <meta
          property="og:title"
          content="Портфолио | CodeSolutions  - Решения для цифрового успеха"
        />
        <meta
          property="og:description"
          content="Портфолио наших проектов в области веб-разработки, включая лендинги, корпоративные сайты, бизнес-сайты и онлайн-магазины."
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Портфолио | CodeSolutions - Решения для цифрового успеха"
        />
        <meta
          name="twitter:description"
          content="Портфолио наших проектов в области веб-разработки, включая лендинги, корпоративные сайты, бизнес-сайты и онлайн-магазины."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />

        <meta
          name="twitter:card"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />

        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta
          property="og:url"
          content="https://codesolutions.agency/portfolio"
        />
      </Helmet>
      <main className="Portfolio">
        <ProjectsContainer />
        <WhyWe />
        <FormBlock />
      </main>
    </>
  );
}

export default Portfolio;
