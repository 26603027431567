import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import "../../css/Reset.css";
import "../../css/App.css";

import { Canvas, pagination } from "../../modules.js";

import Header from "../../components/header.jsx";
import FormBlock from "../../components/formBlock.jsx";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function MainSection() {
  return (
    <>
      <canvas id="bgCanvas"></canvas>
      <section id="main" className="MainBlock ">
        <Header />

        <div className="titleBlock">
          <h1>
            Создаем <span className="">продающие</span> сайты <br /> для{" "}
            <span className="">Вашего</span> бизнеса
          </h1>

          <h3>Digital-агенство CodeSolutions</h3>
        </div>

        <div className="linksOnSite">
          <a href="/portfolio">Портфолио</a>
          <a href="#prices">Цены</a>
          <a href="#footer">Контакты</a>
        </div>
      </section>
    </>
  );
}

function Services() {
  const title = useRef(null);
  const tiles = useRef(null);
  const services = [
    {
      service: "Интернет магазин",
      description: "Делаем разработку на Opencart, WordPress, ModX",
      link: "/onlinestore",
      price: "от 800$",
    },
    {
      service: "Корпоративные сайты",
      description: "Корпоративные сайты любой сложности на WordPress, ModX",
      link: "/corporat",
      price: "от 600$",
    },
    {
      service: "Landing page",
      description: "Продающие лендинги на WordPress, ModX",
      link: "/landing",
      price: "от 300$",
    },
    {
      service: "Бизнес сайт",
      description: "Сайты услуг и продуктов.",
      link: "/buisness",
      price: "от 500$",
    },
    {
      service: "Сайт визитка",
      description: "Простые сайты на WordPress, Modx.",
      link: "/buisnesscard",
      price: "от 250$",
    },
    {
      service: "Доработка сайта",
      description:
        "Внедряем новые фичи, развиваем функционал, оптимизируем работу модулей и плагинов.",
      link: "#",
      price: "от 270$",
    },
    {
      service: "Дизайн сайта",
      description:
        "Используем простые и смелые решения, которые запомнятся посетителям и выделят вас среди конкурентов.",
      link: "/disign",
      price: "от 400$",
    },
    {
      service: "Редизайн сайта",
      description:
        'Помогаем клиентам быть впереди конкурентов. Обновляем дизайн, добавляем новые "фишки", повышая конверсию.',
      link: "/redisign",
      price: "от 400$",
    },
    {
      service: "Техническая поддержка",
      description: "Поддерживаем и развиваем созданные и существующие проекты.",
      link: "#",
      price: "от 300$",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            title.current.style.animationPlayState = "running";
            console.log("Пользователь доскроллил до bottom-block!");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    );

    if (title.current) {
      observer.observe(title.current);
    }

    return () => {
      if (title.current) {
        observer.unobserve(title.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    );

    if (title.current) {
      observer.observe(title.current);
    }

    const tiles = document.querySelectorAll(".tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      if (title.current) {
        observer.unobserve(title.current);
      }
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="services" id="prices">
      <div className="container">
        <h2 ref={title}>Услуги</h2>

        <div className="tiles-container">
          {services.map((service, index) => {
            return (
              <div ref={tiles} className="tile" key={index}>
                <div className="top">
                  <ul>
                    <li>{service.service}</li>
                  </ul>
                  <p>{service.description}</p>
                </div>

                <div className="row">
                  <a href={service.link}>
                    Узнать больше
                    <img
                      src={process.env.PUBLIC_URL + "img/svg/arrow-right.svg"}
                      alt="arrowRight"
                    />
                  </a>
                  <p>{service.price}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Portfolio() {
  const projects = [
    {
      type: "Landing",
      name: "HooBank",
      image: "/img/projectsImages/hoobank.png",
      link: `${process.env.PUBLIC_URL}/projects/hoobank/index.html`,
    },
    {
      type: "Бизнес-сайт",
      name: "VR Nes Immercy",
      image: "/img/projectsImages/VR.png",
      link: `${process.env.PUBLIC_URL}/projects/VR.html`,
    },
    {
      type: "Landing",
      name: "Cyberpunk Promo",
      image: "/img/projectsImages/cyberpunk.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Cyberpunk.html`,
    },
    {
      type: "Landing",
      name: "Webovio",
      image: "/img/projectsImages/webovio.png",
      link: `${process.env.PUBLIC_URL}/projects/Webovio.html`,
    },
    {
      type: "Бизнес сайт",
      name: "Simple",
      image: "/img/projectsImages/simple.png",
      link: `${process.env.PUBLIC_URL}/projects/Simple.html`,
    },
    {
      type: "Landing",
      name: "Konstruct",
      image: "/img/projectsImages/konstruct.png",
      link: `${process.env.PUBLIC_URL}/projects/Konstruct.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "Korm vet agro",
      image: "/img/projectsImages/kormvetagro.png",
      link: "https://kormvetagro.by",
    },
    {
      type: "Бизнес сайт",
      name: "Go Games",
      image: "/img/projectsImages/gogames.png",
      link: `${process.env.PUBLIC_URL}/projects/Gogames.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "Eatly",
      image: "/img/projectsImages/eatly.png",
      link: `${process.env.PUBLIC_URL}/projects/Gogames.html`,
    },
    {
      type: "Корпоративный сайт",
      name: "Expoforum",
      image: "/img/projectsImages/expoforum.jpg",
      link: `${process.env.PUBLIC_URL}/projects/Expoforum.html`,
    },
    {
      type: "Landing",
      name: "McDonald's NFT",
      image: "/img/projectsImages/mcdonald.png",
      link: `${process.env.PUBLIC_URL}/projects/MCdonaldNFT/index.html`,
    },
    {
      type: "Понравились наши кейсы?",
      name: "Хотите увидеть еще?",
      image: "/img/projectsImages/CS.png",
      link: `/portfolio`,
    },
  ];  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const currentTile = entry.target;
            const imageUrl = currentTile.getAttribute("data-src");
            if (imageUrl) {
              currentTile.style.backgroundImage = `url(${imageUrl})`;
              currentTile.removeAttribute("data-src"); 
            }
            entry.target.style.animationPlayState = "running";
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    );

    const tiles = document.querySelectorAll(".portfolio .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);
  return (
    <section className="portfolio">
      <div className="container">
        <h2 className="scrollToThis">Портфолио</h2>
        <div className="tiles-container">
          {projects.map((project, index) => {
            return (
              <>
                <div
                  className="tile"
                  data-src={`${process.env.PUBLIC_URL + project.image}`} // Добавьте URL изображения как data-src
                  key={index}
                >
                  <div className="bg">
                    <div className="block">
                      <p>{project.type}</p>
                      <h3>{project.name}</h3>
                    </div>
                    <a rel="noreferrer" target="_blank" href={project.link}>
                      Смотреть кейс
                    </a>
                  </div>
                </div>
                ;
              </>
            );
          })}
        </div>

        <div className="pagination"></div>
      </div>
    </section>
  );
}

function AboutUs() {
  return (
    <section className="aboutUs">
      <div className="left">
        <h2>
          Digital-агенство СSolutions: традиции создания сайтов, работающая по
          всему миру!
        </h2>
        <p>
          CodeSolutions — это 3 года успешной работы и более 150 удачных
          проектов. Мы растем и продолжаем развиваться.
        </p>
        <p>
          У нас заказывают проекты под ключ разного уровня сложности. Нам
          удалось утвердить свою репутацию по всему СНГ и даже по Европе, ведь
          мы предлагаем удачное сочетание цены и качества!
        </p>
      </div>

      <div className="right">
        <div className="letters">CS</div>
      </div>
    </section>
  );
}

function Home() {
  useEffect(() => {
    if (window.innerWidth <= 768) {
      pagination(3, ".portfolio");
    } else {
      pagination(6, ".portfolio");
    }
    const animationId = Canvas();
    document.querySelector(".burger").classList.add("mainBlack");
    return () => cancelAnimationFrame(animationId);
  }, []);

  return (
    <>
      <Helmet>
        <title>CodeSolutions - Ваши решения для цифрового успеха</title>
        <meta
          name="description"
          content="CodeSolutions - создаем продающие сайты для вашего бизнеса. Услуги веб-разработки, SEO оптимизации, создание корпоративных сайтов и интернет-магазинов."
        />
        <meta
          name="keywords"
          content="веб-разработка, создание сайтов, SEO, интернет-магазин, корпоративный сайт, лендинг"
        />
        <meta
          property="og:title"
          content="CodeSolutions - Ваши решения для цифрового успеха"
        />
        <meta
          property="og:description"
          content="CodeSolutions - создаем продающие сайты для вашего бизнеса. Услуги веб-разработки, SEO оптимизации, создание корпоративных сайтов и интернет-магазинов."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta property="og:url" content="https://codesolutions.agency" />
        <meta
          name="twitter:card"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta
          name="twitter:title"
          content="CodeSolutions - Ваши решения для цифрового успеха"
        />
        <meta
          name="twitter:description"
          content="CodeSolutions - создаем продающие сайты для вашего бизнеса. Услуги веб-разработки, SEO оптимизации, создание корпоративных сайтов и интернет-магазинов."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
      </Helmet>
      <MainSection />
      <Services />
      <Portfolio />
      <FormBlock />
      <WhyWeBlock />
      <AboutUs />
      <ContactsSection />
    </>
  );
}

export default Home;
