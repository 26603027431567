import React from "react";

import "../css/Reset.css";
import "../css/App.css";
function ContactsSection() {
  return (
    <section className="ContactsSection">
      <div className="container">
        <div className="left">
          <h2>
            Хотите сделать что-то великое? <br /> Позвольте помочь вам.
          </h2>

          <h3>Готовы? Свяжитесь сегодня.</h3>

          <div className="block">
            <p>Обсудить ваш проект</p>
            <a href="https://t.me/CodeSolutions_Manager">Project Manager</a>
          </div>

          <div className="block">
            <p>Другие вопросы (партнерство, вакансии...)</p>
            <a href="mailto: codesolutions.manager@gmail.com">
              CodeSolutions@gmail.com
            </a>
          </div>
        </div>
        <div className="right">
          <a className="linkToForm" href="#formBlock">
            Отправить заявку
          </a>
          <div className="row">
            <a href="https://t.me/CodeSolutions_Manager" className="tg">
              <img src={process.env.PUBLIC_URL + "img/svg/tg.svg"} alt="" />
              Telegram
            </a>

            <a href="https://wa.me/375293183836" className="wa">
              <img src={process.env.PUBLIC_URL + "img/svg/wa.svg"} alt="" />
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactsSection;
