import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header.jsx";
import { FooterComp } from "./components/footer.jsx";

import LandingPage from "./pages/landing";
import HomePage from "./pages/home";
import BusinessPage from "./pages/buisness";
import BusinessCardPage from "./pages/buisnesscard";
import CorporatePage from "./pages/corporat";
import OnlineStorePage from "./pages/onlinestore";
import Design from "./pages/design";
import ReDesign from "./pages/redesign";
import Portfolio from "./pages/portfolio";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/buisness" element={<BusinessPage />} />
        <Route path="/buisnesscard" element={<BusinessCardPage />} />
        <Route path="/corporat" element={<CorporatePage />} />
        <Route path="/onlinestore" element={<OnlineStorePage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/design" element={<Design />} />
        <Route path="/redesign" element={<ReDesign />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
      <FooterComp />
    </Router>
  );
}

export default App;
