import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">400$</span>
          </p>

          <h1>
            Создание и разработка
            <span className="blue"> веб-дизайна</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Первое на что обращает внимание пользователь сайта — это его дизайн.
            Именно он усиливает бренд, увеличивает продажи и просто повышает
            доверие к компании. Если ваш сайт перестал выполнять эти функции, то
            на это есть две причины: он устарел, или просто сделан
            непрофессионально. Но в любом случае, ему требуется редизайн.
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Дизайн сайта",
      description:
        "Разрабатываем яркий и впечатляющий UI дизайн. Уделяем особое внимание Mobile First",
    },

    {
      name: "Разработка прототипа",
      description:
        "Проектируем качественный UX дизайн. Продумываем путь взаимодействия пользователя с контентом.",
    },

    {
      name: "Фирменный стиль",
      description:
        "Создаем корпоративный стиль компании. Разрабатываем лого, подбираем фирменные цвета и шрифты.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Какие услуги мы предлагаем?</h2>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);

  const steps = [
    {
      name: "Исследование",
      description:
        "Анализ конкурентов и новых тенденций, поиск и разбор примеров.",
    },

    {
      name: "Составляем ТЗ",
      description:
        "На этом этапе мы формулируем техническое задание, включая все требования к функционалу, дизайну и структуре проекта. Это основа для дальнейшей разработки и воплощения идеи в жизнь.",
    },
    {
      name: "Прототип",
      description:
        "Прорабатывание пользовательских сценариев. Создание прототипов.",
    },
    {
      name: "Контент",
      description:
        "Формирование и размещение контента, подбор медиа элементов и применение их на прототипе.",
    },
    {
      name: "Оформление",
      description:
        "Формирование визуального ряда, проработка деталей макета. Анимация элементов сайта.",
    },
    {
      name: "Результат",
      description:
        "Окончательно завершаем работу над дизайном, вы вносите правки и передаем его в работу разработчикам",
    },
  ];

  return (
    <section className="steps">
      <h2>
        Этапы создания <br /> дизайна
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по разработке Дизайна сайта</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что входит в разработку дизайна?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Качественный дизайн включает в себя продумывание пути
              пользователя, разработку прототипов всех страниц сайта для всех
              основных разрешений экрана, а также непосредственно сами макеты
              страниц на основе прототипов для всех основных разрешений экрана.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Сколько стоит разработать UI/UX дизайн?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Бюджет зависит от количества страниц и требованиям по уровню
              дизайна (WOW-дизайн, строгий корпоративный, минималистичный и
              т.д.). Вилка цен колеблется от 300$ до 1000$.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Сколько вариантов дизайна Вы делаете?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Наша задача не закидать вас множеством вариантов картинок, а
              сделать дизайн продуманным и эффективным для выполнения целей
              поставленных перед сайтом. Поэтому мы стараемся провести
              исследования, определить лучшие решения для отзывчивого и
              понятного дизайна, и показать именно тот вариант, который
              удовлетворит требования клиента по стилю и способу подачи
              информации.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        import {Helmet} from "react-helmet";
        <Helmet>
          <title>
            Веб-дизайн | CodeSolutions - Решения для цифрового успеха
          </title>
          <meta
            name="description"
            content="Разработка уникального веб-дизайна, который усиливает ваш бренд, увеличивает продажи и повышает доверие к компании."
          />
          <meta
            name="keywords"
            content="веб-дизайн, UI/UX дизайн, разработка дизайна, создание дизайна сайта, дизайн визитной карточки"
          />
          <meta
            property="og:title"
            content="Веб-дизайн | CodeSolutions - Решения для цифрового успеха"
          />
          <meta
            property="og:description"
            content="Разработка уникального веб-дизайна, который усиливает ваш бренд, увеличивает продажи и повышает доверие к компании."
          />
          <meta property="og:type" content="website" />
          <meta
            name="twitter:title"
            content="Веб-дизайн | CodeSolutions - Решения для цифрового успеха"
          />
          <meta
            name="twitter:description"
            content="Разработка уникального веб-дизайна, который усиливает ваш бренд, увеличивает продажи и повышает доверие к компании."
          />
          <meta
            name="twitter:image"
            content={process.env.PUBLIC_URL + "/favicon.ico"}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + "/favicon.ico"}
          />
          <meta
            property="og:url"
            content="https://codesolutions.agency/design"
          />
        </Helmet>
      </Helmet>
      <main className="design">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
