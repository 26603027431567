import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">250$</span>
          </p>

          <h1>
            Создание и разработка сайтов
            <span className="blue"> сайта визитки</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Разработка сайта-визитки не занимает много времени, ведь он имеет
            малое количество страниц. Мы обсуждаем с Вами тематику проекта,
            утверждаем макет. Наша команда обеспечивает каждому клиенту
            индивидуальный подход. Спустя короткий срок Вы получаете
            качественный сайт-визитку, полностью соответствующий ожиданиям.
          </p>

          <p>
            Команда CodeSolutions даже такой простой сайт делает интересным и
            нестандартным. Мы “живем” каждым проектом и создаем интересные и
            оригинальные веб-ресурсы.
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Позиционирование",
      description:
        "Сайт создаёт первое впечатление и транслирует философию компании, улучшая имидж на рынке.",
    },

    {
      name: "Маркетинг",
      description:
        "С помощью сайта возможна рекламная кампания по продвижению вашего бизнеса в интернете.",
    },

    {
      name: "Информирование",
      description:
        "На сайте представлена вся информация о компании, описания ее деятельности и услуг.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Какие задачи решает сайт визитка?</h2>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);

  const steps = [
    {
      name: "Анализ",
      description:
        "Исследуем тематику вашего бизнеса, анализируем конкурентов.",
    },

    {
      name: "Составляем ТЗ",
      description:
        "На этом этапе мы формулируем техническое задание, включая все требования к функционалу, дизайну и структуре проекта. Это основа для дальнейшей разработки и воплощения идеи в жизнь.",
    },
    {
      name: "Дизайн",
      description:
        "Разрабатываем дизайн, который оставляет яркое впечатление о компании.",
    },
    {
      name: "Разработка",
      description:
        "Делаем сайт с интерактивным дизайном, динамическими элементами и адаптивной версткой.",
    },
    {
      name: "Контент",
      description:
        "Наполняем сайт актуальной информацией о деятельности вашей компании.",
    },
    {
      name: "Запуск",
      description: "Поддерживаем сайт, помогаем управлять контентом.",
    },
  ];

  return (
    <section className="steps">
      <h2>
        Этапы создания <br /> Корпоративного сайта
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по разработке сайта-визитки</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что такое Сайт визитка?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Сайт визитка содержит как правило до 5 информационных страниц и
              подходит для частных лиц или небольших компаний. Задача сайта
              информировать о роде деятельности и виде предоставляемых услуг,
              получить заявки от заинтересованных в сотрудничестве
              пользователей.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какая цена и сроки на создание Сайта визитки?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Бюджет и сроки на разработку Сайта визитки определяется
              требованиями по уровню дизайна страниц, количеством страниц и
              объемом информации.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Что лучше Сайт визитка или Landing Page?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Если вы активно продвигаете определенную услугу или продукт и ваша
              задача получить как можно больше откликов с рекламы через сайт, в
              таком случае лучше создавать Landing Page. Если
              услуг/продуктов/направлений несколько и объем информации
              достаточно большой для одной страницы, тогда лучше разработать
              сайт визитку.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Сайт-визитка | CodeSolutions - Решения для цифрового успеха
        </title>
        <meta
          name="description"
          content="Разработка сайтов-визиток для представления вашего бизнеса в интернете, улучшения имиджа и привлечения новых клиентов."
        />
        <meta
          name="keywords"
          content="визитная карточка, разработка сайтов, бизнес-сайты, веб-разработка, создание сайтов"
        />
        <meta
          property="og:title"
          content="Сайт-визитка | CodeSolutions - Решения для цифрового успеха"
        />
        <meta
          property="og:description"
          content="Разработка сайтов-визиток для представления вашего бизнеса в интернете, улучшения имиджа и привлечения новых клиентов."
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Сайт-визитка | CodeSolutions - Решения для цифрового успеха"
        />
        <meta
          name="twitter:description"
          content="Разработка сайтов-визиток для представления вашего бизнеса в интернете, улучшения имиджа и привлечения новых клиентов."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "/favicon.ico"}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "/favicon.ico"}
        />
        <meta
          property="og:url"
          content="https://codesolutions.agency/businesscard"
        />
      </Helmet>{" "}
      <main className="buisnessCard">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
