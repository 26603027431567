import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">800$</span>
          </p>

          <h1>
            Разработка
            <span className="blue"> интернет магазина под ключ</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Занимаемся созданием интернет магазинов с 2021 года, внедряем все
            необходимые модули и функции, которые побуждают посетителя к
            совершению дополнительных покупок. Делаем работу интернет-магазина
            простой и быстрой благодаря специальным инструментам. Разработанные
            нами готовые решения позволят ускорить процесс создания
            интернет-магазина, благодаря чему значительно приблизится срок
            запуска и получение первой прибыли с продаж.
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Увеличение объема продаж",
      description:
        "Все любят покупать онлайн. Причин множество, но согласитесь если вы в продажах и вас нет онлайн, рано или поздно вас вытеснят конкуренты.",
    },

    {
      name: "Расширение рынка сбыта",
      description:
        "Без привязки к локации. Продавайте товары клиентам со всей страны!",
    },

    {
      name: "Продажи 24/7!",
      description:
        "Посудите сами: работая круглосуточно, компания получит больше прибыли, чем в случае привязки к режиму “С 10:00 до 19:00”.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Какие задачи решает интернет магазин?</h2>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);
  const steps = [
    {
      name: "Анализ",
      description: "Изучаем ЦА и конкурентов, готовим seo на этапе разработки.",
    },
    {
      name: "Прототип",
      description:
        "Создаем путь пользователя и его взаимодействие с контентом.",
    },
    {
      name: "Составляем ТЗ",
      description:
        "На этом этапе мы формулируем техническое задание, включая все требования к функционалу, дизайну и структуре проекта. Это основа для дальнейшей разработки и воплощения идеи в жизнь.",
    },
    {
      name: "Дизайн",
      description:
        "Разрабатываем дизайн который делает покупки онлайн максимально понятными и приятными.",
    },
    {
      name: "Разработка",
      description:
        "Делаем сайт с интерактивным дизайном, динамическими элементами и адаптивной версткой.",
    },
    {
      name: "Результат",
      description:
        "Запускаем проект с автоматизированными функциями, настроенными метриками, интеграцией и синхронизацией с сопутствующими сервисами.",
    },
  ];
  return (
    <section className="steps">
      <h2>
        Этапы создания <br /> интернет магазина
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по разработке Интернет - магазина</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что лучше интернет-магазин, каталог или Landing Page?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Зависит от типа и количества товаров. Если у вас более 10 единиц
              товара, которые можно доставить клиенту в течении нескольких дней
              - нужен интернет-магазин. Если товаров до 10 и не планируется
              расширение ассортимента, подойдет Landing Page. Если товары идут
              под заказ, то в зависимости от их кол-ва подойдет каталог или
              Landing Page.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какая цена разработки интернет-магазина?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Бюджет на создание интернет-магазина зависит в основном от
              функционала который необходимо реализовать.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какой срок создания Интернет-магазина?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Сроки в среднем варьируются от 1,5 до 2,5 месяцев, в зависимости
              от требований по функциональным возможностям, количества товаров и
              дополнительных синхронизаций и интеграций с CRM и ERP.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        <title>Интернет-магазин под ключ | CodeSolutions</title>
        <meta
          name="description"
          content="Разработка интернет-магазина под ключ с интеграцией всех необходимых модулей и функций для увеличения продаж. Профессиональные решения для вашего бизнеса от CodeSolutions."
        />
        <meta
          name="keywords"
          content="интернет-магазин, создание сайта, веб-разработка, онлайн-магазин, разработка под ключ"
        />
        <meta
          property="og:title"
          content="Интернет-магазин под ключ | CodeSolutions"
        />
        <meta
          property="og:description"
          content="Разработка интернет-магазина под ключ с интеграцией всех необходимых модулей и функций для увеличения продаж. Профессиональные решения для вашего бизнеса от CodeSolutions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta
          property="og:url"
          content="https://codesolutions.agency/onlinestore"
        />
        <meta
          name="twitter:title"
          content="Интернет-магазин под ключ | CodeSolutions"
        />
        <meta
          name="twitter:description"
          content="Разработка интернет-магазина под ключ с интеграцией всех необходимых модулей и функций для увеличения продаж. Профессиональные решения для вашего бизнеса от CodeSolutions."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="onlinestore">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
