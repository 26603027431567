import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">500$</span>
          </p>

          <h1>
            Создание и разработка
            <span className="blue"> Бизнес сайтов</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Сегодня сложно представить компанию без сайта. Одним из важных
            факторов формирования мнения о бренде, является не только наличие
            сайта, но и его состояние. Когда клиенты сравнивают вас с
            конкурентами, у них не должно оставаться сомнений o лидерстве вашей
            компании на рынке.
          </p>

          <p>
            Мы обновляем старые и создаем новые сайты, которые усиливают позиции
            и выделяют вашу компанию среди конкурентов
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Позиционирование",
      description:
        "Сайт создаёт первое впечатление и транслирует философию компании, улучшая имидж на рынке.",
    },

    {
      name: "Маркетинг",
      description:
        "С помощью сайта возможна рекламная кампания по продвижению вашего бизнеса в интернете.",
    },

    {
      name: "Масштаб",
      description:
        "Вы имеете возможность привлечь новых клиентов и партнеров с других регионов и стран, тем самым масштабируя свой бизнес",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Какие задачи решает Бизнес сайт?</h2>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);

  const steps = [
    {
      name: "Анализ",
      description:
        "Исследуем продукт/услугу бизнеса, анализируем конкурентов и аудиторию компании.",
    },
    {
      name: "Прототип",
      description:
        "Создаем путь пользователя и его взаимодействие с контентом.",
    },
    {
      name: "Составляем ТЗ",
      description:
        "На этом этапе мы формулируем техническое задание, включая все требования к функционалу, дизайну и структуре проекта. Это основа для дальнейшей разработки и воплощения идеи в жизнь.",
    },
    {
      name: "Дизайн",
      description:
        "Разрабатываем дизайн, который оставляет яркое впечатление о компании.",
    },
    {
      name: "Разработка",
      description:
        "Делаем сайт с интерактивным дизайном, динамическими элементами и адаптивной версткой.",
    },
    {
      name: "Запуск",
      description:
        "Следим за аналитикой, тестируем, улучшаем и поддерживаем результат.",
    },
  ];

  return (
    <section className="steps">
      <h2>
        Этапы создания <br /> Корпоративного сайта
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по разработке Бизнес сайта</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что такое Бизнес сайт?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Бизнес сайт это многостраничный сайт, который подходит малому и
              среднему бизнесу. Его задача заключается в формировании имиджа
              компании в онлайн среде, предоставления информации об услугах и
              возможностях компании, привлечении новых клиентов и партнеров.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какая цена и сроки на создание Бизнес сайта?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Бюджет и сроки на разработку Бизнес сайта определяется несколькими
              этапами работ. Прежде всего это анализ рынка, построение структуры
              разделов, формирование позиционирования. Затем этап UI/UX дизайна,
              и этап разработки.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            В чем разница между Корпоративным и Бизнес сайтом?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Бизнес сайты как правило проще по структуре и меньше по количеству
              страниц, не содержат внутренних разделов для зарегистрированных
              пользователей. Такие сайты подходят для малого и среднего бизнеса.
              Корпоративные сайты имеют довольно объемную структуру с
              несколькими вложенностями и предназначены для среднего и крупного
              бизнеса.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        <title>Бизнес сайт | CodeSolutions</title>
        <meta
          name="description"
          content="Разработка Бизнес сайтов, которые помогают малому и среднему бизнесу укрепить свои позиции на рынке и привлечь новых клиентов."
        />
        <meta
          name="keywords"
          content="бизнес сайт, создание сайтов, разработка сайтов, веб-разработка, веб-дизайн"
        />
        <meta property="og:title" content="Бизнес сайт | CodeSolutions" />
        <meta
          property="og:description"
          content="Разработка Бизнес сайтов, которые помогают малому и среднему бизнесу укрепить свои позиции на рынке и привлечь новых клиентов."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta property="og:url" content="https://codesolutions.agency/buisness" />
        <meta name="twitter:title" content="Бизнес сайт | CodeSolutions" />
        <meta
          name="twitter:description"
          content="Разработка Бизнес сайтов, которые помогают малому и среднему бизнесу укрепить свои позиции на рынке и привлечь новых клиентов."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="buisness">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
