import React, { useEffect, useState } from "react";
import { burger, openCurrentBlock } from "../modules.js";
import "../css/Reset.css";
import "../css/App.css";

function lazyLoading() {
  var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    // Возможно, добавить резервный вариант для браузеров, которые не поддерживают IntersectionObserver
  }
}

function Header() {
  useEffect(() => {
    lazyLoading();
  }, []);
  const [activeBlock, setActiveBlock] = useState("");

  function handleBlockClick(blockName) {
    if (activeBlock === blockName) {
      setActiveBlock("");
    } else {
      setActiveBlock(blockName);
    }
  }

  let allFloatBlocks = document.querySelectorAll(".routePCBlock");
  let allButtons = document.querySelectorAll("header button");
  document.addEventListener("click", function (event) {
    let isButton = Array.from(allButtons).some((button) =>
      button.contains(event.target)
    );
    let isFloatBlock = Array.from(allFloatBlocks).some((block) =>
      block.contains(event.target)
    );

    if (!isButton && !isFloatBlock) {
      allFloatBlocks.forEach((block) => {
        block.classList.remove("s");
      });
    }
  });

  return (
    <>
      <header>
        <nav>
          <div className="languageBlock">
            <a href="/" className="logo">
              <span className="blue">Code</span>
              <span className="white_underLine">Solutions</span>
            </a>
          </div>
          <ul>
            <li className="development">
              <button
                onClick={() => handleBlockClick("routePCBlockDevelopment")}
              >
                Разработка
              </button>
            </li>

            <li className="">
              <button onClick={() => handleBlockClick("design")}>Дизайн</button>
            </li>

            <li>
              <a href="/portfolio">Портфолио</a>
            </li>

            <li>
              <a href="#footer">Контакты</a>
            </li>

            <li className="mobile">
              <button onClick={() => burger()}></button>
              <button onClick={() => burger()}></button>
              <button onClick={() => burger()}></button>
            </li>
          </ul>

          <div
            className={`routePCBlock routePCBlockDevelopment ${
              activeBlock === "routePCBlockDevelopment" ? "s" : ""
            }`}
          >
            <ul>
              <li>
                <a href="/landing">LandingPage</a>
              </li>

              <li>
                <a href="/corporat">Корпоративный сайт</a>
              </li>

              <li>
                <a href="/onlinestore">Интернет магазин</a>
              </li>

              <li>
                <a href="/buisness">Бизнес сайт</a>
              </li>

              <li>
                <a href="/buisnesscard">Сайт визитка</a>
              </li>
            </ul>
          </div>
          <div
            className={`routePCBlock routePCBlockDesign ${
              activeBlock === "design" ? "s" : ""
            }`}
          >
            <ul>
              <li>
                <a href="/design">Дизайн</a>
              </li>

              <li>
                <a href="/redesign">Редизайн</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="burger mobile">
        <button
          value=".development"
          onClick={(event) => openCurrentBlock(event)}
        >
          Разработка
        </button>
        <div className="development block">
          <a href="/landing">LandingPage</a>
          <a href="/corporat">Корпоративный сайт</a>
          <a href="/onlinestore">Интернет-магазин</a>
          <a href="/buisness">Бизнес сайт</a>
          <a href="/buisnesscard">Сайт визитка</a>
        </div>
        <button value=".design" onClick={(event) => openCurrentBlock(event)}>
          Дизайн
        </button>
        <div className="design block">
          <a href="/design">Дизайн</a>
          <a href="/redesign">Редизайн</a>
        </div>
        <a href="/portfolio" className="defaultLink">
          Портфолио
        </a>
        <button value=".contacts" onClick={(event) => openCurrentBlock(event)}>
          Контакты
        </button>
        <div className="contacts block">
          <a href="https://t.me/CodeSolutions_Manager">Project Manager</a>
          <a href="mailto:codesolutions.manager@gmail.com">Почта</a>
        </div>
      </div>
    </>
  );
}

export default Header;
