import React from "react";

import "../css/Reset.css";
import "../css/App.css";

function FooterComp() {
  return (
    <footer id="footer">
      <nav>
        <div className="block col-j-e">
          <a href={process.env.PUBLIC_URL + "/"} className="logo">
            <span className="blue">Code</span>
            <span className="black_underLine">Solutions</span>
          </a>

          <div className="inblock">
            <p>
              Любовь к вашему бизнесу <br /> Это приоритет для нас
            </p>
          </div>

          <div className="inblockRow">
            <a href="https://t.me/CodeSolutions_Manager" className="tg">
              <img src={process.env.PUBLIC_URL + "img/svg/tg.svg"} alt="" />
              Telegram
            </a>
            <a href="https://wa.me/375293183836" className="wa">
              <img src={process.env.PUBLIC_URL + "img/svg/wa.svg"} alt="" />
              WhatsApp
            </a>
          </div>
        </div>

        <div className="block">
          <h3>Услуги</h3>
          <ul>
            <li>
              <a href="/landing">Landing page</a>
            </li>
            <li>
              <a href="/corporat">Корпоративный сайт</a>
            </li>
            <li>
              <a href="/onlinestore">Интернет-магазин</a>
            </li>
            <li>
              <a href="/buisiness">Бизнес сайт</a>
            </li>
            <li>
              <a href="/buisnesscard">Сайт визитка</a>
            </li>
            <li>
              <a href="/design">Дизайн сайта</a>
            </li>
            <li>
              <a href="/redisign">Редизайн сайта</a>
            </li>
          </ul>
        </div>

        <div className="block">
          <h3>Информация</h3>
          <ul>
            <li>
              <a href="/#main">О нас</a>
            </li>
            <li>
              <a href="/#prices">Услуги</a>
            </li>
            <li>
              <a href="/portfolio">Портфолио</a>
            </li>
            <li>
              <a href="/">Блог</a>
            </li>
          </ul>
        </div>
      </nav>

      <div className="bottom-block">
        <p>
          © Copyright 2021-2024 Все права защищены. Частичное или полное
          копирование информации сайта возможно только с разрешения
        </p>
      </div>
    </footer>
  );
}
export { FooterComp };
