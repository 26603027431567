import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock.jsx";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">400$</span>
          </p>

          <h1>
            Создание и разработка
            <span className="blue"> редизайна</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Разработка сайта-визитки не занимает много времени, ведь он имеет
            малое количество страниц. Мы обсуждаем с Вами тематику проекта,
            утверждаем макет. Наша команда обеспечивает каждому клиенту
            индивидуальный подход. Спустя короткий срок Вы получаете
            качественный сайт-визитку, полностью соответствующий ожиданиям.
          </p>

          <p>
            Команда CodeSolutions даже такой простой сайт делает интересным и
            нестандартным. Мы “живем” каждым проектом и создаем интересные и
            оригинальные веб-ресурсы.
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Редизайн логотипов",
      description:
        "Выполняется, если лого компании устарело или является малоудобным в использовании.",
    },

    {
      name: "Изменения цветовой гаммы, шрифтов",
      description:
        "Выполняется для соответствия новому логотипу, создания нового запоминающегося дизайна, использования современных фишек.",
    },

    {
      name: "Изменение контента",
      description:
        "Для ускоренного открытия страниц избавляются от лишнего контента: удаляют устаревшие разделы и подразделы, меняют картинки на более качественные. При необходимости добавляют новый контент (те же разделы и подразделы), а также обновляют текстовую составляющую.",
    },

    {
      name: "Внедрение элементов с анимацией",
      description:
        "Они создают ощущение взаимодействия. А при грамотном применении могут даже увеличить конверсию.",
    },
    {
      name: "Проработка HTML-кода",
      description:
        "Это важно для увеличения эффективности продвижения в поисковых системах.",
    },
    {
      name: "Оптимизация юзабилити",
      description:
        "Выполняются работы по улучшению удобства пользования сайтом: создается новое меню, улучшается навигация, добавляются поисковые фильтры и прочие элементы.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Что такое редизайн сайта и какие задачи он включает?</h2>
        <p className="gray">
          Редизайн сайта – не просто изменение оформления. Он также
          предусматривает серьезные правки в контенте и функциональности
          веб-ресурса. Вплоть до переезда площадки на другой движок. Исходя из
          требований заказчика редизайн веб сайта включает в себя разные пункты:
        </p>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);

  const steps = [
    {
      name: "Разработка концепции.",
      description:
        "На данном этапе анализируются топовые сайты и изучаются основные решения, которые помогают сделать веб-ресурс удобным и интуитивно понятным для пользователей.",
    },

    {
      name: "Подготовка новой контентной составляющей.",
      description: "С ориентацией на нее создается новый макет.",
    },
    {
      name: "Разработка макета.",
      description: "Выполняется на основе уже готовой концепции.",
    },
    {
      name: "Верстка.",
      description:
        "Выполняется воплощение дизайна макета в жизнь. И заодно – создание сайта, ориентированного на конкретную аудиторию",
    },
    {
      name: "Разработка.",
      description:
        "Вносятся правки в код, выполняются работы по оптимизации, ускоряется открытие страниц.",
    },
    {
      name: "Тестирование.",
      description:
        "Проверяется работоспособность нового дизайна, удобство пользования сайтом, наличие ошибок. При необходимости вносятся оперативные правки.",
    },
  ];

  return (
    <section className="steps">
      <h2>
        Основные этапы <br /> редизайна сайта
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по редизайну сайта</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что такое Редизайн сайта?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Если вы понимаете, что дизайн вашего сайта устарел и больше не
              выполняет свои задачи, необходимо обновить его в соответствии с
              современными требованиями как по удобству пользования, так и по
              стилевому оформлению.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Сколько стоит редизайн сайта?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Для начала мы изучим пожелания и определим задачи, которые должен
              выполнять сайт. Далее составим перечень необходимых работ, оценим
              трудозатраты в человеко-часах и умножим на стоимость часа работы
              специалиста (от 10 до 15 $/час).
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Как происходит процесс редизайна?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Начинается процесс с разработки прототипов страниц, далее на их
              основе разрабатываются адаптивные дизайн-макеты. Затем мы
              выгружаем копию текущего сайта на тестовый сервер и внедряем
              обновления согласно утвержденным макетам. Таким образом текущий
              сайт никак не пострадает, пользователи не будут наблюдать процесс
              внедрения обновлений, а увидят конечный результат.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Редизайн сайта | CodeSolutions - Обновление и улучшение вашего
          веб-дизайна
        </title>
        <meta
          name="description"
          content="Профессиональный редизайн сайта для улучшения внешнего вида, удобства использования и повышения конверсии. Обновите свой сайт с CodeSolutions."
        />
        <meta
          name="keywords"
          content="редизайн сайта, обновление сайта, улучшение сайта, дизайн сайта, CodeSolutions"
        />
        <meta
          property="og:title"
          content="Редизайн сайта | CodeSolutions - Обновление и улучшение вашего
          веб-дизайна"
        />
        <meta
          property="og:description"
          content="Профессиональный редизайн сайта для улучшения внешнего вида, удобства использования и повышения конверсии. Обновите свой сайт с CodeSolutions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta
          property="og:url"
          content="https://codesolutions.agency/redisign"
        />
        <meta name="twitter:title" content="Редизайн сайта | CodeSolutions" />
        <meta
          name="twitter:description"
          content="Профессиональный редизайн сайта для улучшения внешнего вида, удобства использования и повышения конверсии. Обновите свой сайт с CodeSolutions."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="redisign">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
