import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

import "../css/Reset.css";
import "../css/App.css";

function FormBlock() {
  const options = [
    { value: "Интернет-магазин", label: "Интернет-магазин" },
    { value: "Корпоративные сайты", label: "Корпоративные сайты" },
    { value: "Landing page", label: "Landing page" },
    { value: "Бизнес сайт", label: "Бизнес сайт" },
    { value: "Сайт визитка", label: "Сайт визитка" },
    { value: "Доработка сайта", label: "Доработка сайта" },
    { value: "Дизайн сайта", label: "Дизайн сайта" },
    { value: "Редизайн сайта", label: "Редизайн сайта" },
    { value: "Техническая поддержка", label: "Техническая поддержка" },
  ];

  const moneyOptions = [
    { value: "< 400$", label: "Меньше 400$" },
    { value: "> 400 && < 800", label: "от 400$ до 800$" },
    { value: "> 800", label: "от 800$" },
  ];

  const [selectedOption, setSelectedOption] = useState();
  const [selectedMoney, setSelectedMoney] = useState();

  const handleChange_ServiceType = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleChange_MoneyRange = (selectedMoney) => {
    setSelectedMoney(selectedMoney);
  };

  const SelectType = () => (
    <Select
      name="serviceType"
      data-flag="0"
      value={selectedOption}
      onChange={handleChange_ServiceType}
      options={options}
      placeholder="Выберите услугу"
    />
  );

  const SelectMoney = () => (
    <Select
      name="moneyRange"
      data-flag="0"
      value={selectedMoney}
      onChange={handleChange_MoneyRange}
      options={moneyOptions}
      placeholder="Ваш бюджет"
    />
  );
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [messenger, setMessenger] = useState("");

  const sendForm = async (event) => {
    event.preventDefault();
    const TOKEN = "5978216449:AAFNja0PSKZEp-pIcFMHh387_8jCtuQqoq4";
    const CHAT_ID = "-1002043343945";
    const MESSAGE_THREAD_ID = 43;
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    let message = `<b style="color: #2900ff">Новая заявка с сайта CodeSolutions</b>\n`;
    message += `<b>Имя клиента: </b>${name}\n`;
    message += `<b>Номер клиента: </b>${tel}\n`;
    message += `<b>Удобное мессенджер для клиента: </b>${messenger}\n`;
    message += `<b>Выбранная услуга: </b>${selectedOption.label}\n`;
    message += `<b>Бюджет клиента: </b>${selectedMoney.label}\n`;
    
    try {
      await axios.post(URI_API, {
        chat_id: CHAT_ID,
        message_thread_id: MESSAGE_THREAD_ID,
        parse_mode: "html",
        text: message,
      });
      console.log("Форма успешно отправлена");
      setName("");
      setTel("");
      setMessenger("");
      setSelectedOption();
      setSelectedMoney();
    } catch (error) {
      console.error("Ошибка при отправке формы: ", error);
    }
  };

const checkIF_null = (e) => {
  const currentInput = e.target;
  if (currentInput.value !== "") {
    currentInput.setAttribute("data-flag", 1);
    currentInput.style.borderColor = "#cccccc"; // Возвращаем обычный цвет, если поле не пустое
  } else {
    currentInput.setAttribute("data-flag", 0);
    currentInput.style.borderColor = "#ff0000"; // Устанавливаем красный цвет для пустого поля
    setTimeout(() => {
      currentInput.style.borderColor = "#cccccc"; // Возвращаем обычный цвет через секунду
    }, 1000);
  }
};

  const checkBeforeSend = (event) => {
    event.preventDefault();
    let allFieldsValid = true;
    const fields = [
      document.querySelector("input.name"),
      document.querySelector("input.tel"),
      document.querySelector("input.messanger"),
    ];

    fields.forEach((field) => {
      if (field.getAttribute("data-flag") === "0") {
        field.style.borderColor = "#ff0000";
        setTimeout(() => {
          field.style.borderColor = "#ccc";
        }, 700);
        allFieldsValid = false;
      }
    });

    if (allFieldsValid) {
      sendForm(event);
    }
  };
  useEffect(() => {
    const formBlock = document.getElementById("formBlock");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target
              .querySelectorAll(
                ".SendToTelegram input, .SendToTelegram button, .SendToTelegram select, .SendToTelegram .css-b62m3t-container"
              )
              .forEach((child, index) => {
                const delay = index * 0.1;
                child.style.animationDelay = `${delay}s`;
                child.style.animationPlayState = "running";
              });
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(formBlock);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <section className="formBlock" id="formBlock">
        <form className="SendToTelegram">
          <h2>Есть вопросы?</h2>
          <p>Давайте обсудим ваши задачи. Свяжитесь сегодня!</p>
          <input
            type="text"
            data-flag="0"
            name="name"
            className="name"
            value={name}
            onInput={(e) => {
              setName(e.target.value);
              checkIF_null(e);
            }}
            placeholder="Введите ваше имя *"
          />
          <input
            type="tel"
            name="tel"
            className="tel"
            data-flag="0"
            value={tel}
            onInput={(e) => {
              setTel(e.target.value);
              checkIF_null(e);
            }}
            placeholder="Введите ваш номер телефона"
          />
          <input
            type="text"
            data-flag="0"
            className="messanger"
            name="messenger"
            value={messenger}
            onInput={(e) => {
              setMessenger(e.target.value);
              checkIF_null(e);
            }}
            placeholder="Ваш мессенджер для связи"
          />
          {/* <select name="siteType">
            <option value="" disabled selected>
              Выберите тип услуги
            </option>
            {options.map((data, index) => {
              return (
                <>
                  <option key={data} value={data}>
                    {data}
                  </option>
                </>
              );
            })}
          </select> */}

          <SelectType />
          <SelectMoney />
          <button
            type="submit"
            onClick={(event) => {
              checkBeforeSend(event);
            }}
          >
            Отправить
          </button>
        </form>

        <img
          className="whiteBlock lazy"
          data-src={process.env.PUBLIC_URL + "img/formImage.svg"}
          alt=""
        />
      </section>
    </>
  );
}

export default FormBlock;
