import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../css/Reset.css";
import "./css/styles.css";

import FormBlock from "../../components/formBlock";
import WhyWeBlock from "../../components/WhyWeBlock.jsx";
import ContactsSection from "../../components/ContactsSection.jsx";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="container">
        <div className="left">
          <p>
            Стоимость от <span className="blue">300$</span>
          </p>

          <h1>
            Создание и разработка <br />
            <span className="blue">Landing Page</span>
          </h1>
        </div>

        <div className="right">
          <p>
            Landing page — сайт, который создают для определенной цели.
            Например, для оформления заказа. Конечно, есть и другие задачи,
            например, сбор личных данных пользователя. Все зависит от типа
            бизнеса. Команда профессионалов CodeSolutions занимается разработкой
            лендинга с высокой конверсией. Дизайн такого сайта привлекательный,
            а информация легко воспринимается пользователем.
          </p>
        </div>
      </div>
    </section>
  );
}

function Tasks() {
  const tiles = [
    {
      name: "Быстрый старт",
      description:
        "Запуск лендинга обеспечит получение заявок/продаж максимально быстро, по сравнению с запуском интернет-магазина или бизнес сайта.",
    },

    {
      name: "Конверсия",
      description:
        "Лендинг прекрасно работает на продажи. Страница полностью концентрирует внимание на продукте или услуге.",
    },

    {
      name: "Увеличение прибыли",
      description:
        "С помощью лендинга, вы можете запускать новые продукты или услуги отдельно от основного сайта.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      }
    );

    const tiles = document.querySelectorAll(".tasks .tile");
    tiles.forEach((tile) => observer.observe(tile));

    return () => {
      tiles.forEach((tile) => observer.unobserve(tile));
    };
  }, []);

  return (
    <section className="tasks">
      <div className="floatBottomTrinagle float"></div>
      <div className="floatTopTrinagle float"></div>
      <div className="container">
        <h2>Какие задачи решает Landing page?</h2>
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            return (
              <div className="tile" key={index}>
                <div className="row">
                  <h4>{(index + 1).toString().padStart(2, "0")}</h4>
                  <h3>{tile.name}</h3>
                </div>
                <p>{tile.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function Steps() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animationPlayState = "running"; // Добавляем класс для анимации
          } else {
            entry.target.style.animationPlayState = "paused"; // Удаляем класс, если элемент вышел из области видимости
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const stepBlocks = document.querySelectorAll(".steps .block"); // Выбираем все блоки шагов
    stepBlocks.forEach((block) => observer.observe(block)); // Наблюдаем за каждым блоком

    return () => stepBlocks.forEach((block) => observer.unobserve(block)); // Отписываемся при размонтировании компонента
  }, []);

  const steps = [
    {
      name: "Анализ",
      description:
        "Исследуем продукт/услугу бизнеса, анализируем конкурентов и аудиторию компании.",
    },
    {
      name: "Прототип",
      description:
        "Создаем путь пользователя и его взаимодействие с контентом.",
    },
    {
      name: "Составляем ТЗ",
      description:
        "На этом этапе мы формулируем техническое задание, включая все требования к функционалу, дизайну и структуре проекта. Это основа для дальнейшей разработки и воплощения идеи в жизнь.",
    },
    {
      name: "Дизайн",
      description:
        "Делаем сочный дизайн с призывом к действию. Создаем маркетинговые ловушки.",
    },
    {
      name: "Разработка",
      description:
        "Делаем сайт с интерактивным дизайном, динамическими элементами и адаптивной версткой.",
    },
    {
      name: "Запуск и Аналитика",
      description:
        "Следим за конверсией созданного лендинга. Тестируем и улучшаем результат.",
    },
  ];

  return (
    <section className="steps">
      <h2>
        Этапы создания <br /> Landing Page
      </h2>

      <div className="container">
        {steps.map((step, index) => {
          return (
            <div className="block" key={index}>
              <div className="row-a-c">
                <div className="circle">
                  <div className="inCircle">{index + 1}</div>
                </div>

                <div className="line"></div>
              </div>

              <div className="inblock">
                <h3>{step.name}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function OftenQuestions() {
  function openQuestionBlock(event) {
    const currentButton = event.currentTarget;
    currentButton.classList.toggle("activeButtonQuest");
    const needBlock = currentButton.nextElementSibling;
    needBlock.classList.toggle("activeInBlock");
  }
  return (
    <section className="oftenQuestions">
      <h2>Частые вопросы по разработке Landing Page</h2>
      <div className="container">
        <div className="block">
          <button
            className="activeButtonQuest"
            onClick={(event) => openQuestionBlock(event)}
          >
            Что лучше Landing page или многостраничный сайт?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock activeInBlock">
            <p>
              Если вы продвигаете отдельный товар/услугу, лучше разрабатывать
              Landing page, т.к. все внимание пользователя сосредоточится именно
              на конкретном товаре/услуге и он с большей вероятностью совершит
              нужное целевое действие.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какая цена на создание Landing page?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock ">
            <p>
              Бюджет на разработку Landing page определяется несколькими этапами
              работ. Прежде всего это анализ рынка, построение структуры
              страницы, формирование контента. Затем этап UI/UX дизайна, и этап
              разработки.
            </p>
          </div>
        </div>

        <div className="block">
          <button onClick={(event) => openQuestionBlock(event)}>
            Какой срок создания Landing page?
            <img
              src={process.env.PUBLIC_URL + "img/svg/arrow-right-black.svg"}
              alt=""
            />
          </button>
          <div className="inBlock">
            <p>
              Сроки зависят от уровня дизайна и от количества экранов (смысловых
              блоков) на странице. В среднем на создание качественного и
              стильного Landing page уходит от 4 дней до 1 рабочей недели.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default function LandingPage() {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("white");
  }, []);
  return (
    <>
      <Helmet>
        <title>Landing Page | CodeSolutions</title>
        <meta
          name="description"
          content="Разработка Landing Page с высокой конверсией для вашего бизнеса. Профессиональный дизайн и эффективные маркетинговые решения от CodeSolutions."
        />
        <meta
          name="keywords"
          content="landing page, создание сайта, веб-разработка, дизайн сайта, продающий сайт"
        />
        <meta property="og:title" content="Landing Page | CodeSolutions" />
        <meta
          property="og:description"
          content="Разработка Landing Page с высокой конверсией для вашего бизнеса. Профессиональный дизайн и эффективные маркетинговые решения от CodeSolutions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta
          property="og:url"
          content="https://codesolutions.agency/landing"
        />
        <meta
          name="twitter:title"
          content="Создание Landing Page - Ваша компания"
        />
        <meta
          name="twitter:description"
          content="Разработка Landing Page с высокой конверсией для вашего бизнеса. Профессиональный дизайн и эффективные маркетинговые решения от CodeSolutions."
        />
        <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="landing">
        <FirstScreen />
        <Tasks />
        <Steps />
        <WhyWeBlock />
        <OftenQuestions />
        <FormBlock />
        <ContactsSection />
      </main>
    </>
  );
}
